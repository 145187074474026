import { CandidateProcess } from '@/Domain/Entities';
import { InscriptionStatus } from '@/Domain/enum';
import CandidateService from '@/Services/CandidateService';

export const UserStore = {
    namespaced: true,
    state: {
        dni: '',
        adminBlockedProcessIds: [] as string[],
        authData: undefined
    },
    mutations: {
        setDni(state, dni: string) {
            state.dni = dni;
        },
        setAdminBlockedProcessIds(state, adminBlockedProcessIds: string[]) {
            state.adminBlockedProcessIds = adminBlockedProcessIds;
        },
        setAuthData(state, authData: { role: string, saviaHubClientId: string }) {
            state.authData = authData;
        }
    },
    actions: {
        async fetchAdminBlockedProcessesIds({ commit }, dni: string) {
            try {
                if (!dni || dni === '') {
                    return;
                }
                const response = await CandidateService.getCandidateInfo(dni);
                const adminBlockedProcessIds = response.processes ?
                    response.processes
                        .filter((process: CandidateProcess) => process.blockAccessToAdmin && process.inscriptionStatus !== InscriptionStatus.enum.EXCLUDED && process.inscriptionStatus !== InscriptionStatus.enum.DRAFT)
                        .map((process: CandidateProcess) => process.processId) : [];
                commit('setAdminBlockedProcessIds', adminBlockedProcessIds);
                return adminBlockedProcessIds;
            } catch (error) {
                console.log(error);
            }
        }
    },
    getters: {
        getDni(state): string {
            return state.dni;
        },
        getAdminBlockedProcessIds(state): string[] {
            return state.adminBlockedProcessIds;
        },
        getAuthData(state): { role: string, saviaHubClientId: string } {
            return state.authData;
        }
    }
};
