import { Address } from '@/Domain/Entities';
import { Constants } from '@/Domain/enum';
import MasterDataService from '@/Services/MasterDataService';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})

export default class AddressForm extends Vue {

  // computed
  get isAddressCountrySpain() {
    return this.address.country === Constants.country.SPAIN_CODE;
  }

  get isLocalMunicipalityRequired() {
    return this.isAddressCountrySpain && Boolean(this.address.province);
  }
  @Prop() address!: Address;
  @Prop() isDisabled!: boolean;
  @Prop() submitted!: boolean;
  @Prop() nationalities!: any;
  @Prop() provinces!: any;
  @Prop() autonomousCommunityList!: any;
  @Prop() streetTypes!: any;
  @Prop() countries!: any;

  municipalities: any[] = [];

  getMunicipalities() {
    this.$nextTick(() => {
      const province = this.address.province ? this.address.province : null;

      if (!province) {
        this.address.municipality = null;
        this.onFieldUpdate('', 'province');
        return;
      }
      
      MasterDataService.getMunicipalities(province)
        .then((response: any) => {
          const candidateMunicipalitie = this.address ? this.address.municipality : null;
          this.municipalities = response.map(municipality => ({ name: municipality.municipalityName, id: municipality.municipalityCode, disabled: municipality.isObsolete }))
            .filter(municipality => !municipality.disabled || (candidateMunicipalitie !== null && candidateMunicipalitie === municipality.id));
        })
        .catch(error => console.log(error));
    });
  }

  onFieldUpdate(updatedValue: string, key: string) {
    const newAddress = new Address({ ...this.address.toServerInscription(), [key]: updatedValue});
    this.$emit('onFieldUpdate', newAddress);
  }

  onProvinceUpdate(updatedValue: string) {
    this.address.municipality = null;
    this.onFieldUpdate(updatedValue, 'province');
  }
  
  mounted() {
    this.getMunicipalities();
    this.setSpainCountryByDefault();
  }

  private setSpainCountryByDefault() {
    if (!this.address.country) { this.address.country = Constants.country.SPAIN_CODE; }
  }
}
