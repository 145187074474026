import moment from 'moment';

export default class ReplacementChange {
    private _replacementListCandidateId: string;
    private _reason: string | null;
    private _changeDate: moment.Moment;

    public constructor(data: { replacementListCandidateId: string, reason: string | null, changeDate: string }) {
        this._replacementListCandidateId = data.replacementListCandidateId;
        this._reason = data.reason;
        this._changeDate = moment.utc(data.changeDate).local();
    }

    public get id() {
        return this._replacementListCandidateId;
    }

    public set id(id: string) {
        this._replacementListCandidateId = id;
    }

    public get reason() {
        return this._reason;
    }

    public set reason(reason: string | null) {
        this._reason = reason;
    }

    public get changeDate() {
        return this._changeDate;
    }

    public set changeDate(date: moment.Moment) {
        this._changeDate = date;
    }

    public get timeString() {
      return this._changeDate.format('HH:mm');
    }

    public get move() {
      return this._reason ? this._reason.split(':')[0] : null;
    }

    public get reasonFormatted() {
        return this._reason ? this._reason.split(':')[1] : null;
      }

}
