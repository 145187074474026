import Vue from 'vue';
import Router from 'vue-router';
import { ProcessType } from '@/Domain/enum';
import { SecurityService } from '@/Application/Services/SecurityService';
import { store } from '@/Store';

// VIEWS
import Layout from '@/Views/Layout.vue';
import BOEPList from '@/Views/BOEP/BOEPList.vue';
import BOEPEdit from '@/Views/BOEP/BOEPEdit.vue';
import ProcessList from '@/Views/Process/ProcessList.vue';
import ProcessEdit from '@/Views/Process/ProcessEdit.vue';
import ListManagementEdit from '@/Views/ListManagement/ListManagementEdit.vue';
import ListManagementList from '@/Views/ListManagement/ListManagementList.vue';
import RatingsList from '@/Views/Rating/RatingsList.vue';
import ScalingEdit from '@/Views/Rating/ScalingEdit.vue';
import ExamsEdit from '@/Views/Rating/ExamsEdit.vue';
import RankingEdit from '@/Views/Rating/RankingEdit.vue';
import ReplacementList from '@/Views/Replacement/ReplacementList.vue';
import ReplacementEdit from '@/Views/Replacement/ReplacementEdit.vue';
import CourtList from '@/Views/Court/CourtList.vue';
import CourtEdit from '@/Views/Court/CourtEdit.vue';
import ReplacementStatusContract from '@/Views/Replacement/ReplacementStatusContract.vue';
import ListReplacementList from '@/Views/Replacement/ListReplacementList.vue';
import ListReplacementProvisional from '@/Views/Replacement/ListReplacementProvisional.vue';
import Error404 from '@/Views/Error404/Error404.vue';
import LegalTexts from '@/Views/LegalTexts/LegalTexts.vue';
import AccessDenied from '@/Views/AccessDenied/AccessDenied.vue';
import LegalWarning from '@/Views/LegalWarning/LegalWarning.vue';
import CookiesPolicy from '@/Views/CookiesPolicy/CookiesPolicy.vue';
import PrivacyPolicy from '@/Views/PrivacyPolicy/PrivacyPolicy.vue';
import Dashboard from '@/Views/Dashboard/Dashboard.vue';
import InscriptionEdit from '@/Views/Inscription/InscriptionEdit';
import ProcessConfiguration from '@/Views/ProcessConfiguration/ProcessConfiguration.vue';
import ShippingHistoryList from '@/Views/Replacement/ShippingHistoryList.vue';
import ShippingHistoryReceivers from '@/Views/Replacement/ShippingHistoryReceivers.vue';
import RatingsEdit from '@/Views/Rating/RatingsEdit.vue';
import AdminEnrolledOnBlockedProcessRouteGuardService from '@/Services/AdminEnrolledOnBlockedProcessRouteGuardService';
import { Roles } from '@/Domain/enum';

const mgr = new SecurityService();

Vue.use(Router);
export const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Layout',
      component: Layout,
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard,
          meta: {
            fullWidth: true,
            areAllRolesAllowed: false,
            role: [Roles.ADMIN]
          }
        },
        {
          path: 'BOEP',
          name: 'BOEP',
          redirect: { name: 'BOEPList' },
          component: {
            template: '<router-view/>'
          },
          children: [
            {
              path: 'all',
              name: 'BOEPList',
              component: BOEPList,
              meta: {
                fullWidth: true,
                areAllRolesAllowed: false,
                role: [Roles.ADMIN]
              }
            },
            {
              path: 'new',
              name: 'BOEPNew',
              component: BOEPEdit,
              meta: {
                areAllRolesAllowed: false,
                role: [Roles.ADMIN]
              }
            },
            {
              path: ':id/edit',
              name: 'BOEPEdit',
              component: BOEPEdit,
              meta: {
                areAllRolesAllowed: false,
                role: [Roles.ADMIN]
              }
            }
          ]
        },
        {
          path: 'process',
          name: 'Process',
          redirect: { name: 'ProcessList' },
          component: {
            template: '<router-view/>'
          },
          children: [
            {
              path: 'all',
              name: 'ProcessList',
              component: ProcessList,
              meta: {
                fullWidth: true,
                areAllRolesAllowed: false,
                role: [Roles.ADMIN]
              }
            },
            {
              path: 'new',
              name: 'CallNew',
              component: ProcessEdit,
              meta: {
                type: ProcessType.enum.CALL,
                areAllRolesAllowed: false,
                role: [Roles.ADMIN]
              }
            },
            {
              path: ':id/edit',
              name: 'CallEdit',
              component: ProcessEdit,
              meta: {
                type: ProcessType.enum.CALL,
                areAllRolesAllowed: false,
                role: [Roles.ADMIN]
              }
            },
            {
              path: 'new',
              name: 'BagNew',
              component: ProcessEdit,
              meta: {
                type: ProcessType.enum.BAG,
                areAllRolesAllowed: false,
                role: [Roles.ADMIN]
              }
            },
            {
              path: ':id/edit',
              name: 'BagEdit',
              component: ProcessEdit,
              meta: {
                type: ProcessType.enum.BAG,
                areAllRolesAllowed: false,
                role: [Roles.ADMIN]
              }
            },
            {
              path: 'new',
              name: 'PostProvisionNew',
              component: ProcessEdit,
              meta: {
                type: ProcessType.enum.POSTPROVISION,
                areAllRolesAllowed: false,
                role: [Roles.ADMIN]
              }
            },
            {
              path: ':id/edit',
              name: 'PostProvisionEdit',
              component: ProcessEdit,
              meta: {
                type: ProcessType.enum.POSTPROVISION,
                areAllRolesAllowed: false,
                role: [Roles.ADMIN]
              }
            }
          ]
        },
        {
          path: 'listManagement',
          name: 'ListManagement',
          redirect: { name: 'ListManagementList' },
          component: {
            template: '<router-view/>'
          },
          children: [
            {
              path: 'all',
              name: 'ListManagementList',
              component: ListManagementList,
              meta: {
                fullWidth: true,
                areAllRolesAllowed: false,
                role: [Roles.ADMIN]
              }
            },
            {
              path: ':id/inscriptions',
              name: 'ListManagementEdit',
              component: ListManagementEdit,
              meta: {
                fullWidth: true,
                areAllRolesAllowed: false,
                role: [Roles.ADMIN]
              }
            },
            {
              path: ':id/inscriptionEdit/:inscriptionId',
              name: 'InscriptionEdit',
              component: InscriptionEdit,
              meta: {
                areAllRolesAllowed: true
              }
            }
          ]
        },
        {
          path: 'ratings',
          name: 'ratings',
          redirect: { name: 'RatingsList' },
          component: {
            template: '<router-view/>'
          },
          children: [
            {
              path: 'all',
              name: 'RatingsList',
              component: RatingsList,
              meta: {
                fullWidth: true,
                areAllRolesAllowed: true
              }
            },
            {
              path: 'RatingsEdit/:tabActive/:id',
              name: 'RatingsEdit',
              component: RatingsEdit,
              meta: {
                fullWidth: true,
                areAllRolesAllowed: true
              },
              children: [
                {
                  path: 'exams',
                  name: 'ExamsEdit',
                  component: ExamsEdit,
                  meta: {
                    fullWidth: true,
                    areAllRolesAllowed: true
                  }
                },
                {
                  path: 'scaling',
                  name: 'ScalingEdit',
                  component: ScalingEdit,
                  meta: {
                    fullWidth: true,
                    areAllRolesAllowed: true
                  }
                },
                {
                  path: 'ranking',
                  name: 'RankingEdit',
                  component: RankingEdit,
                  meta: {
                    fullWidth: true,
                    areAllRolesAllowed: true
                  }
                }
              ]
            }
          ]
        },
        {
          path: 'replacement',
          name: 'replacement',
          redirect: { name: 'ReplacementList' },
          component: {
            template: '<router-view/>'
          },
          children: [
            {
              path: 'all',
              name: 'ReplacementList',
              component: ReplacementList,
              meta: {
                fullWidth: true,
                areAllRolesAllowed: false,
                role: [Roles.ADMIN]
              }
            },
            {
              path: 'new',
              name: 'ReplacementNew',
              component: ReplacementEdit,
              meta: {
                fullWidth: true,
                areAllRolesAllowed: false,
                role: [Roles.ADMIN]
              }
            },
            {
              path: ':id/edit',
              name: 'ReplacementListEdit',
              component: ReplacementEdit,
              meta: {
                fullWidth: true,
                areAllRolesAllowed: false,
                role: [Roles.ADMIN]
              }
            },
            {
              path: ':replacementId/list',
              name: 'ListReplacementList',
              component: ListReplacementList,
              meta: {
                fullWidth: true,
                areAllRolesAllowed: false,
                role: [Roles.ADMIN]
              }
            },
            {
              path: 'provisional',
              name: 'ListReplacementProvisional',
              component: ListReplacementProvisional,
              meta: {
                fullWidth: true,
                areAllRolesAllowed: false,
                role: [Roles.ADMIN]
              }
            },
            {
              path: ':id/replacementInfo/:replacementListCandidateId/:fromShippingHistory?',
              name: 'ReplacementStatusContract',
              component: ReplacementStatusContract,
              meta: {
                fullWidth: true,
                areAllRolesAllowed: false,
                role: [Roles.ADMIN]
              }
            },
            {
              path: ':id/shippingHistory/all',
              name: 'ShippingHistoryList',
              component: ShippingHistoryList,
              meta: {
                fullWidth: true,
                areAllRolesAllowed: false,
                role: [Roles.ADMIN]
              }
            },
            {
              path: ':id/shippingHistory/:shippingHistoryId/receiversList',
              name: 'ShippingHistoryReceivers',
              component: ShippingHistoryReceivers,
              meta: {
                fullWidth: true,
                areAllRolesAllowed: false,
                role: [Roles.ADMIN]
              }
            }
          ]
        },
        {
          path: 'legalTexts',
          name: 'legalTexts',
          redirect: { name: 'LegalTexts' },
          component: {
            template: '<router-view/>'
          },
          children: [
            {
              path: 'definition',
              name: 'LegalTexts',
              component: LegalTexts,
              meta: {
                fullWidth: true,
                areAllRolesAllowed: false,
                role: [Roles.ADMIN]
              }
            }
          ]
        },
        {
          path: 'processConfiguration',
          name: 'processConfiguration',
          redirect: { name: 'ProcessConfiguration' },
          component: {
            template: '<router-view/>'
          },
          children: [
            {
              path: 'definition',
              name: 'ProcessConfiguration',
              component: ProcessConfiguration,
              meta: {
                fullWidth: true,
                areAllRolesAllowed: false,
                role: [Roles.ADMIN]
              }
            }
          ]
        },
        {
          path: 'Court',
          name: 'Court',
          redirect: { name: 'CourtList' },
          component: {
            template: '<router-view/>'
          },
          children: [
            {
              path: 'all',
              name: 'CourtList',
              component: CourtList,
              meta: {
                fullWidth: true,
                areAllRolesAllowed: false,
                role: [Roles.ADMIN]
              }
            },
            {
              path: 'new',
              name: 'CourtNew',
              component: CourtEdit,
              meta: {
                fullWidth: true,
                areAllRolesAllowed: false,
                role: [Roles.ADMIN]
              }
            },
            {
              path: ':email/edit',
              name: 'CourtEdit',
              component: CourtEdit,
              meta: {
                fullWidth: true,
                areAllRolesAllowed: false,
                role: [Roles.ADMIN]
              }
            }
          ]
        },
        {
          path: '/accessdenied',
          name: 'AccessDenied',
          component: AccessDenied,
          meta: {
            areAllRolesAllowed: true
          }
        },
        {
          path: '/legalwarning',
          name: 'LegalWarning',
          component: LegalWarning,
          meta: {
            areAllRolesAllowed: true
          }
        },
        {
          path: '/cookiesPolicy',
          name: 'CookiesPolicy',
          component: CookiesPolicy,
          meta: {
            areAllRolesAllowed: true
          }
        },
        {
          path: '/privacyPolicy',
          name: 'PrivacyPolicy',
          component: PrivacyPolicy,
          meta: {
            areAllRolesAllowed: true
          }
        }
      ]
    },
    {
      path: '*',
      name: 'Default',
      redirect: { name: 'Error404' },
      component: Layout,
      children: [{
        path: 'Error404',
        name: 'Error404',
        component: Error404,
        meta: {
          fullWidth: true,
          areAllRolesAllowed: true
        }
      }]

    }
  ]
});

router.beforeEach(async (to, from, next) => {
  const authData = store.state.userStore.authData ? store.state.userStore.authData : await mgr.getTokenInfo();
  store.commit('userStore/setAuthData', authData);

  if (!authData || authData.role === Roles.NOROLE || authData.role === Roles.DISABLED) {
    if (to.path !== '/accessdenied') {
      next('/accessdenied');
    } else {
      next();
    }
    return;
  }

  const { adminBlockedProcessIds } = await AdminEnrolledOnBlockedProcessRouteGuardService.getAdminBlockedAndEnrolledProcessIds(store, mgr);
  const isAdminDeniedOnRoute = AdminEnrolledOnBlockedProcessRouteGuardService.isAdminDeniedIOnRoute(to.name ? to.name : '', [ ...adminBlockedProcessIds ], to.params);

  if (isAdminDeniedOnRoute) {
    next('/accessdenied');
    return;
  }

  if (!to.meta.areAllRolesAllowed) {
    if (to.path === '/') {
      if (authData.role === Roles.ADMIN) {
        next('/dashboard');
      } else {
        next('/ratings');
      }
      return;
    }

    if (to.meta.role.includes(authData.role)) {
      next();
    } else {
      next('/accessdenied');
    }
  } else {
    next();
  }
});
