
import Oidc from 'oidc-client';
import 'babel-polyfill';
import { Roles } from '@/Domain/enum';

const splitAt = '//';
const origin = window.location.origin.split(splitAt)[1];
const saviaHubBaseUrl = process.env.VUE_APP_BASE_URL_SAVIA_HUB;

export const mgrObject = new Oidc.UserManager({
  userStore: new Oidc.WebStorageStateStore({}),
  authority: saviaHubBaseUrl,
  client_id: origin,
  redirect_uri: window.location.origin + '/callback.html',
  response_type: 'id_token token',
  scope: 'openid profile email',
  post_logout_redirect_uri: window.location.origin + '/',
  silent_redirect_uri: window.location.origin + '/silent-renew.html',
  accessTokenExpiringNotificationTime: 600,
  automaticSilentRenew: false,
  filterProtocolClaims: true,
  loadUserInfo: true
});

Oidc.Log.level = Oidc.Log.INFO;

mgrObject.events.addUserLoaded(user => {});

mgrObject.events.addAccessTokenExpiring( () => {});

mgrObject.events.addAccessTokenExpired( () => {
  mgrObject.signoutRedirect()
  .then( resp => {})
  .catch( err => {});
});

mgrObject.events.addSilentRenewError( () => {});

mgrObject.events.addUserSignedOut( () => {
  mgrObject.signoutRedirect()
  .then( resp => {})
  .catch( err => {});
});

export class SecurityService {

  // Renew the token manually
  renewToken() {
    const self = this;
    return new Promise((resolve, reject) => {
      mgrObject.signinSilent().then( user => {
        if (user == null) {
          self.signIn();
        } else {
          return resolve(user);
        }
      }).catch( err => {
        return reject(err);
      });
    });
  }

  // Get the user who is logged in
  getUser() {
    const self = this;
    return new Promise((resolve, reject) => {
      mgrObject.getUser().then( user => {
        if (user == null) {
          self.signIn();
          return reject(undefined);
        } else {
          const decodedToken = self.decode(user.access_token);
          user.profile.adminRole = self.setRole(decodedToken);
          return resolve(user);
        }
      }).catch( err => {
        return reject(err);
      });
    });
  }

  // Check if there is any user logged in
  getSignedIn() {
    const self = this;
    return new Promise((resolve, reject) => {
      mgrObject.getUser().then( user => {
        if (user == null) {
          self.signIn();
          return resolve(false);
        } else {
          return resolve(true);
        }
      }).catch( err => {
        return reject(err);
      });
    });
  }

  // Redirect of the current window to the authorization endpoint.
  signIn() {
    mgrObject.signinRedirect()
    .catch( err => {});
  }

  // Redirect of the current window to the end session endpoint
  signOut() {
    mgrObject.signoutRedirect()
    .then( resp => {})
    .catch( err => {});
  }

  // Get the profile of the user logged in
  getProfile() {
    const self = this;
    return new Promise((resolve, reject) => {
      mgrObject.getUser().then( user => {
        if (user == null) {
          self.signIn();
          return resolve(undefined);
        } else {
          const decodedToken = self.decode(user.access_token);
          user.profile.adminRole = self.setRole(decodedToken);
          return resolve(user.profile);
        }
      }).catch( err => {
        return reject(err);
      });
    });
  }

  // Get the token id
  getIdToken() {
    const self = this;
    return new Promise((resolve, reject) => {
      mgrObject.getUser().then( user => {
        if (user == null) {
          self.signIn();
          return resolve(undefined);
        } else {
          return resolve(user.id_token);
        }
      }).catch( err => {
        return reject(err);
      });
    });
  }

  // Get the session state
  getSessionState() {
    const self = this;
    return new Promise((resolve, reject) => {
      mgrObject.getUser().then( user => {
        if (user == null) {
          self.signIn();
          return resolve(undefined);
        } else {
          return resolve(user.session_state);
        }
      }).catch( err => {
        return reject(err);
      });
    });
  }

  // Get the access token of the logged in user
  getAcessToken() {
    const self = this;
    return new Promise((resolve, reject) => {
      mgrObject.getUser().then( user => {
        if (user == null) {
          self.signIn();
          return resolve(undefined);
        } else {
          return resolve(user.access_token);
        }
      }).catch( err => {
        return reject(err);
      });
    });
  }

  getAcessTokenNoSignin() {
    return new Promise((resolve, reject) => {
      mgrObject.getUser().then( user => {
        if (user == null) {
          return resolve(undefined);
        } else {
          return resolve(user.access_token);
        }
      }).catch( err => {
        return reject(err);
      });
    });
  }

  // Takes the scopes of the logged in user
  getScopes() {
    const self = this;
    return new Promise((resolve, reject) => {
      mgrObject.getUser().then( user => {
        if (user == null) {
          self.signIn();
          return resolve(undefined);
        } else {
          return resolve(user.scopes);
        }
      }).catch( err => {
        return reject(err);
      });
    });
  }

  // Get the user roles logged in
  getRole() {
    const self = this;
    return new Promise((resolve, reject) => {
      mgrObject.getUser().then( user => {
        if (user == null) {
          self.signIn();
          return resolve(undefined);
        } else {
          const decodedToken = self.decode(user.access_token);
          user.profile.adminRole = self.setRole(decodedToken);
          return resolve(user.profile.adminRole);
        }
      }).catch( err => {
        return reject(err);
      });
    });
  }

  // Get object with the decoded token
  getTokenInfo(): Promise<{ role: Roles, saviaHubClientId: string } | undefined> {
    const self = this;
    return new Promise((resolve, reject) => {
      mgrObject.getUser().then( user => {
        if (user == null) {
          self.signIn();
          return resolve(undefined);
        } else {
          const decodedToken = self.decode(user.access_token);
          user.profile.adminRole = self.setRole(decodedToken);
          const tokenInfo = {
            role: user.profile.adminRole,
            saviaHubClientId: decodedToken.azp
          };
          return resolve(tokenInfo);
        }
      }).catch( err => {
        return reject(err);
      });
    });
  }

  decode(token) {
    const parts = token.split('.');
    const payloadBase64 = parts[1].replace(/-/g, '+').replace(/_/g, '/');
    const decodedPayload = atob(payloadBase64);

    const payloadObject = JSON.parse(decodedPayload);
  
    return payloadObject;
  }

  setRole(decodedToken) {
    if (decodedToken.resource_access[origin]) {
      return Roles[decodedToken.resource_access[origin].roles[0].toUpperCase()];
    }
    return Roles.NOROLE;
  }
}
