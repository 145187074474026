import { SecurityService } from '@/Application/Services/SecurityService';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { Roles } from '@/Domain/enum';

@Component({
  computed: {
    ...mapGetters('userStore', { authData: 'getAuthData' })
  }
})
export default class AccessDenied extends Vue {
  mgr = new SecurityService();
  authData!: { role: string, saviaHubClientId: string };

  get isUserWithoutValidRole() {
    return this.authData && this.authData.role === Roles.NOROLE || this.authData.role === Roles.DISABLED;
  }
  
  closeModalNoRole() {
    this.mgr.signOut();
  }
}
