import { Component, Vue } from 'vue-property-decorator';
import { RatingsTabs, StatusExam, GradeType, OppositionType, ProcessPhases, Constants } from '@/Domain/enum';
import { Exam, Grade, SorterNew, InputFilterElement, OepDocument, TabsHashExams, ClientInfo } from '@/Domain/Entities';
import { Toggle, MdInput, MdTextarea, FileViewer, Modal, SdSearcher, ExamCard, SdSlideOver } from '@/Components';
import * as notification from '../../../node_modules/saviafront/lib/js/compiled/notification';
import ProcessesService from '@/Services/ProcessesService';
import InscriptionService from '@/Services/InscriptionService';
import { ToasterService } from '@/Services/ToasterService';
import MasterDataService from '@/Services/MasterDataService';
import ClientService from '@/Services/ClientService';
import i18n from '../../lang';
import _ from 'lodash';
import { mapState } from 'vuex';
import DocumentService from '@/Application/Services/DocumentService';
import * as sdPanel from '../../../node_modules/saviafront/lib/js/compiled/sd-panel';
import RatingsEdit from './RatingsEdit';

@Component({
    components: { Toggle, MdInput, MdTextarea, FileViewer, Modal, SdSearcher, ExamCard },
    computed: {
        inscriptionFiltered() {
            return this.$data.gradesList.filter(element => this.$data.searcherFilter.showElement(element));
        },
        examId() {
            if (this.$data.examSelected.status) {
                return this.$data.examSelected.status !== StatusExam.enum.INPROCESS ? this.$data.examSelected.id : null;
            }
        },
        examIdImport() {
            return this.$data.examSelected.id;
        },
        hasGraded() {
            return this.$data.gradesList.some(grades => grades.graded);
        },
        isCreditable() {
            return this.$data.examSelected.creditable;
        },
        allGradesHaveExams() {
            return this.$data.gradesList.some(grade => grade.documents.length);
        },
        examActiveRouteParam() {
            return this.$data.examActive ? `#${this.$data.examActive.id}` : '#';
        },
        orderedGradesList() {
            return this.$data.sorter.sortBy(this.$data.gradesList);
        },
        ...mapState('languagesStore', { currentLanguage: 'current' })
    },
    watch: {
        '$route.params.id': {
            handler(oldId, newId) {
                if (newId !== oldId) {
                    (this as ExamsEdit).prepareExamsView();
                }
                (this as ExamsEdit).initList();
            },
            deep: true
        }
    }
})
export default class ExamsEdit extends Vue {
    currentLanguage!: string;
    hasGraded!: boolean;
    routeNameExam: string = '';
    showSlideOver: boolean = false;
    searcherFilter = new InputFilterElement({ param: ['fullName', 'nif'] });
    processData = {};
    RatingsTabs = RatingsTabs;
    StatusExam = StatusExam;
    GradeType = GradeType;
    ProcessPhases = ProcessPhases;
    OppositionType = OppositionType;
    examsList: Exam[] = [];
    gradesList: Grade[] = [];
    loadingExams = true;
    loadingGrades = true;
    isSaving = false;
    sorter = new SorterNew(['fullName'], ['desc', 'asc']);
    isEditing = false;
    examSelected = new Exam({});
    examListBackup: any[] = [];
    gradesListBackup: any[] = [];
    filesToViewer: OepDocument[] = [];
    viewingFile: any = null;
    errorImportCsv: string[] = [];
    examActiveTosendParent: boolean = false;
    searchCleaned: boolean = false;
    examActive: any = null;
    loadingFile: boolean = false;
    hasExams: boolean = false;
    sorting: boolean = false;
    clientInfo: ClientInfo = new ClientInfo({});

    $refs!: {
        gradesForm: HTMLFormElement,
        panelExamsCandidate: HTMLElement,
        refSdSearcher: SdSearcher
        importer: HTMLFormElement,
        sdSlideOver: SdSlideOver,
        fileViewer: FileViewer,
        refScrollbar: HTMLElement,
        importGradesSlideOver: SdSlideOver
    };
    invalidFileName: any;
    invalidExtension: boolean = false;
    file: any;
    importedGradeFile: any;
    gradeDocumentsOwner: string = '';
    showImportGradesSlideOver: boolean = false;
    showImportGradesFieldEncoding: boolean = false;
    csvScoresValues = [
        'lang.exams.decimal',
        'lang.exams.passed',
        'lang.exams.notPassed',
        'lang.exams.unApplied'
    ];
    gradesCsv = [] as any[];

    downloadCSVExam() {
        if (!this.hasExams) {
            ToasterService.showWarning(i18n.t('lang.shared.noExams') as any);
        } else {
            this.examSelected.status === StatusExam.enum.GRADED ? ProcessesService.getCSVExams(this.$route.params.id, this.examSelected.id) : ToasterService.showWarning(i18n.t('lang.toaster.examUserPending') as any);
        }
    }

    downloadCSVContactDataExam() {
        this.examSelected.id ? ProcessesService.getCSVContactDataExam(this.$route.params.id, this.examSelected.id) : ToasterService.showWarning(i18n.t('lang.shared.noExams') as any);
    }

    downloadCSVExamCall() {
        this.examSelected.id ? ProcessesService.getCSVExamCall(this.$route.params.id, this.examSelected.id) : ToasterService.showWarning(i18n.t('lang.shared.noExams') as any);
    }

    getImportGradesCsvTemplate() {
        ProcessesService.getCSVimportGradesTemplate(this.$route.params.id, this.examSelected.id);
    }

    toggleSlideOverImportGrades() {
        this.showImportGradesSlideOver = !this.showImportGradesSlideOver;
    }

    showFieldEncoding() {
        this.showImportGradesFieldEncoding = !this.showImportGradesFieldEncoding;
    }

    resetFile() {
        this.file = null;
        this.gradesCsv = [];
        this.importedGradeFile = null;
        this.errorImportCsv = [];
    }

    async onFileChange(newFile) {
        this.file = newFile;
        this.file.fileName = newFile.name;
        this.file.allowRemove = true;
        this.gradesCsv.push(newFile);
        this.file.extension = '.' + this.file.fileName.split('.').pop();
        this.invalidExtension = this.file.extension.toLowerCase() !== '.csv';
        this.invalidFileName = Constants.fileImporter.INVALID_FILE_NAME.some((validFormat: string) => this.file.fileName.includes(validFormat));

        if (this.invalidFileName) {
            ToasterService.showError(i18n.t('lang.fileImporter.fileName') as any);
            this.resetFile();
            return;
        }

        if (this.invalidExtension) {
            ToasterService.showError(i18n.t('lang.shared.onlyCsv') as any);
            this.resetFile();
            return;
        }

        this.importedGradeFile = {
            processId: this.$route.params.id,
            examId: this.examSelected.id,
            formFile: newFile
        };
    }

    importGrades() {
        ProcessesService.importCSVExam(this.importedGradeFile).then(response => {
            ToasterService.showSuccess(i18n.t('lang.toaster.gradeSave') as any);
            this.reloadGradesList();
            this.manageError('');
            this.resetFile();
            this.toggleSlideOverImportGrades();
        })
        .catch(error => {
            this.manageError(error);
            this.resetFile();
        });
        this.setTimeOutLoadingFile();
    }

    closeEditionMode() {
        this.gradesList.forEach((grade, index) => {
            this.gradesListBackup[index].activeSearch = grade.activeSearch;
            this.gradesListBackup[index].fullNameToShow = grade.fullNameToShow;
            this.gradesListBackup[index].nifToShow = grade.nifToShow;
        });
        this.examsList = this.examListBackup;
        this.gradesList = this.gradesListBackup;
        this.isEditing = !this.isEditing;
        this.viewingFile = null;
        if (this.searchCleaned) {
            this.gradesList.forEach(element => element.fullNameToShow = element.fullName);
            this.searchCleaned = false;
        }
    }

    setTimeOutLoadingFile() {
        setTimeout(() => {
            this.loadingFile = false;
        }, 245000);
    }

    manageError(error) {
        this.errorImportCsv = [];
        if (error) {
            error = error.split('-- ').pop();
            this.$nextTick(() => {
                this.errorImportCsv = error.split('&');
                this.loadingFile = false;
            });
        } else {
            this.loadingFile = false;
        }
    }

    reloadGradesList() {
        this.initList();
    }

    save() {
        this.viewingFile = null;
        (this as any).$route.params.hash = null;
        this.$router.push({ name: this.routeNameExam, hash: '' });
        this.examActiveTosendParent = !this.examActiveTosendParent;
        if (this.isSaving || !this.examSelected || this.$refs.gradesForm.querySelectorAll(':invalid').length > 0 || this.$refs.gradesForm.querySelectorAll('.error').length > 0) {
            return;
        }
        this.isSaving = true;
        const objectToSend = { grades: this.gradesList.map(element => element.toServer()) };
        ProcessesService.updateGradesExam(this.$route.params.id, this.examSelected.id, objectToSend)
            .then(response => {
                ToasterService.showSuccess(i18n.t('lang.toaster.gradeSave') as any);
                this.isSaving = false;
                this.isEditing = false;
                this.initList(true);
            })
            .catch(() => this.isSaving = false);
    }

    showTooltip(grade) {
        this.viewingFile = null;
        if (!grade.showedTooltip) {
            this.gradesList.forEach(element => element.showedTooltip = false);
            InscriptionService.getHistoryProcessExamsGrades(grade.inscriptionId).then(response => {
                grade.info = response.gradeSummary;
                grade.showedTooltip = !grade.showedTooltip;
            }).catch(error => console.log(error));
        } else {
            grade.showedTooltip = !grade.showedTooltip;
            grade.info = [];
        }
    }

    setEditMode() {
        this.examListBackup = _.cloneDeep(this.examsList);
        this.gradesListBackup = _.cloneDeep(this.gradesList);
        this.gradesList.forEach(element => element.showedTooltip = false);
        this.examsList.forEach(element => element.blocked = !element.active);
        this.isEditing = !this.isEditing;
    }

    sortBy(keys: string[]) {
        this.sorter.updateKeys(keys);
    }

    setExamActive(exam) {
        if (exam.status !== StatusExam.enum.PENDING && !exam.blocked) {
            this.examsList.forEach(examElement => examElement.active = false);
            exam.active = true;
            this.examActiveTosendParent = !this.examActiveTosendParent;
            this.viewingFile = null;
            this.examActive = exam;
            this.getGradesByExam(exam);
            this.$refs.refSdSearcher.removeSearchString();
            this.$router.push({ name: this.routeNameExam, hash: '#' + this.examActive.id });
        }
    }

    getKeepAlive() {
        ClientService.getKeepAlive(this.clientInfo.getClientId())
            .then(() => { })
            .catch(error => console.log(error));
    }

    showInFileViewer(doc: OepDocument) {
        this.getKeepAlive();
        DocumentService.viewFile(doc)
            .then(docUrl => {
                doc.url = window.URL.createObjectURL(docUrl);
                this.viewingFile = doc;
                this.moveScroll(this.viewingFile.id);
            })
            .catch(error => {
                console.log(error);
            });
    }

    buildFilesToView(grade: Grade) {
        this.gradesList.forEach(element => element.showedTooltip = false);
        this.filesToViewer = [];
        grade.documents.forEach(doc => {
            doc = new OepDocument(doc);
            this.filesToViewer.push(doc);
        });
        this.gradeDocumentsOwner = grade.fullNameToShow;
        this.showInFileViewer(this.filesToViewer[0]);
    }

    toggleSlideOver() {
        this.showSlideOver = !this.showSlideOver;
    }

    getGradeInfo(grade: Grade) {
        InscriptionService.getHistoryProcessExamsGrades(grade.inscriptionId).then(response => {
            grade.info = response.gradeSummary;
            grade.showedTooltip = !grade.showedTooltip;
        }).catch(error => console.log(error));
    }

    async getGradesByExam(exam, isUpdate = false) {
        this.resetGradeList();
        if (!isUpdate) {
            this.sorter.sortBy([]);
        }
        if (!exam) {
            this.updateViewLoaders(false);
            return;
        }
        this.updateViewLoaders(true);
        try {
            const response = await ProcessesService.getDataProcessExamsGradesInscriptions(this.$route.params.id, exam.id);
            this.handleExamGrades(response, exam.status, isUpdate);
        } catch (error) {
            console.log(error);
        }
        this.updateViewLoaders(false);
    }

    resetGradeList() {
        this.gradesList = [];
        this.errorImportCsv = [];
    }

    updateViewLoaders(isLoading: boolean) {
        this.loadingGrades = isLoading;
        this.$emit('loadingData');
    }

    handleExamGrades(examGradesResponse: any, examStatus: number, isUpdate: boolean) {
        this.gradesList = examGradesResponse.grades.map(grade => new Grade(grade));
        this.sortBy(['fullName']);
        this.examSelected = new Exam(examGradesResponse);
        this.examSelected.id = examGradesResponse.examId;
        this.examSelected.status = examStatus;
        this.$nextTick(() => {
            sdPanel.initialize();
        });
    }

    removeSearchString() {
        this.searchCleaned = true;
    }

    getDataProcess() {
        ProcessesService.getDataProcess(this.$route.params.id).then((response: any) => {
            this.processData = response;
            this.initList();
        }).catch(error => console.log(error));
    }

    getDataProcessExamsInscriptions(isUpdate?) {
        ProcessesService.getDataProcessExamsInscriptions(this.$route.params.id).then(
            response => {
                this.examsList = response.map(exam => new Exam(exam));
                this.hasExams = this.examsList.length > 0;
                const myTab = new TabsHashExams();
                this.checkIsRankingTabBlocked();
                if (this.$route.hash) {
                    this.examActive = myTab.selectElementWithRouteHash(this.examActive, null, this.examsList, this.$route, null);
                }
                if (this.$route.params.hash) {
                    this.examActive = myTab.selectElementWithParamsHash(this.examActive, this.examsList, this.$route, this.$router, this.routeNameExam);
                    return;
                }
                if (this.examsList.some(element => element.active) && this.examsList.length && !this.$route.params.hash) {
                    this.examActive = myTab.selectElementWithoutHashAndWithExamList(this.examActive, this.examsList, this.$router, this.routeNameExam);
                }
                if (!this.examsList.some(element => element.active) && this.examsList.length && !this.$route.params.hash) {
                    this.examActive = myTab.selectElementWithoutHashAndExamList(this.examActive, this.examsList, this.$router, this.routeNameExam);
                }
                this.getGradesByExam(this.examsList.find(element => element.active), isUpdate);
                this.loadingExams = false;
            }).catch(error => this.loadingExams = false);
    }

    initList(isUpdate?) {
        this.getDataProcessExamsInscriptions(isUpdate);
    }

    initClientInfo() {
        MasterDataService.getClientInfo()
            .then(responseMasterData => {
                this.clientInfo = new ClientInfo(responseMasterData);
            })
            .catch(error => console.log(error));
    }

    moveScroll(scrollBoxIndex: string) {
        this.$nextTick(() => {
            const elementScrollable = document.getElementById('scrollBox_' + scrollBoxIndex);
            if (elementScrollable !== null) {
                this.$refs.refScrollbar.scrollTo({ left: elementScrollable.offsetLeft, behavior: 'smooth' });
            }
        });
    }

    checkIsRankingTabBlocked() {
        const allExamsAreGraded = this.hasExams ? this.examsList.every(exam => exam.status === StatusExam.enum.GRADED) : false;
        if (!allExamsAreGraded) {
            return;
        }
        (this.$parent as RatingsEdit).getIsRankBlocked();
    }

    prepareExamsView() {
        this.initClientInfo();
        this.getDataProcess();
        notification.initialize();
        this.routeNameExam = 'ExamsEdit';
    }

    async goToInscriptionEdit(grade: Grade) {
        this.$router.push(
            {
                name: 'InscriptionEdit',
                params:
                {
                    id: this.$route.params.id,
                    inscriptionId: grade.inscriptionId,
                    from: 'ExamsEdit',
                    hash: this.examActive,
                    title: 'lang.shared.exams',
                    tabActive: RatingsTabs.enum.EXAMS
                }
            });
    }

    mounted() {
        this.prepareExamsView();
    }
}
