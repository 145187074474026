import { DisabilityData, ReplacementCandidate } from '@/Domain/Entities';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})

export default class TurnsForm extends Vue {
  @Prop() isDisabled!: boolean;
  @Prop() candidate!: ReplacementCandidate;
  @Prop() submitted!: boolean;
  @Prop() turnsOnProcess!: { [k: string]: any; };
  @Prop() yesNoTranslations!: any;

  onDisabilityUpdate(updatedValue: string, key: string) {
    const updatedDisability = new DisabilityData({... this.candidate.disabilityData.toServerInscription(), [key]: updatedValue});
    this.$emit('onDisabilityUpdate', updatedDisability);
  }

  onTurnUpdate(updatedValue: string) {
    this.$emit('onTurnUpdate', updatedValue);
  }

}
