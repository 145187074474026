import axios from 'axios';
const baseURL = process.env.VUE_APP_ROOT_API + 'api/ReplacementLists';
import csvService from '@/Services/CsvService';
import { InscriptionReplacement, ReplacementCandidate } from '@/Domain/Entities';
import { IReplacementCandidateIdentification } from '@/Domain/interfaces/IReplacementCandidateIdentification';
export default class ReplacementService {

    public static async getReplacementList(): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.get('', { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async getListReplacementList(replacementId: string): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.get(replacementId + '/inscriptions', { baseURL })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => reject());
        });
    }

    public static async getReplacementById(id: string): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get(id, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async addReplacement(Replacement: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.post('', Replacement, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async updateReplacement(Replacement: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.put(Replacement.id, Replacement, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async updateReplacementIsVisible(replacementId: any, isVisibleReplacement: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.put(replacementId + '/visible', isVisibleReplacement, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async updateClientInfoReplacementListsVisible(isVisibleClientInfo: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.put('/visible', isVisibleClientInfo, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async removeReplacement(id: string): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.delete(id, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }
    public static async updateOrderTiebreakReplacement(replacementId: string, order: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.put(replacementId + '/inscriptions', order, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }
    public static async validateTiebreaks(replacementId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.put(replacementId + '/inscriptions/validateTieBreaks', {}, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }
    public static async updateReplacementListCandidatePosition(moveCandidateCommand: { replacementListId: string, replacementListCandidateId: string, order?: number, moveOption: number, reason?: string }): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.put(moveCandidateCommand.replacementListId + '/replacementListCandidate/' + moveCandidateCommand.replacementListCandidateId, { ...moveCandidateCommand }, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async getReplacementListCandidate(replacementId: string, replacementListCandidateId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get(replacementId + '/replacementListCandidate/' + replacementListCandidateId, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async getImportReplacementListCsvTemplate(): Promise<any[]> {
        return csvService.getCsv(baseURL, '/importReplacementListTemplate');
    }

    public static async getCSVToDownload(replacementListId: string): Promise<any[]> {
        return csvService.getCsv(baseURL, replacementListId + '/replacementListCandidates/csv');
    }

    public static async getCSVContractHistoryToDownload(replacementListId: string): Promise<any[]> {
        return csvService.getCsv(baseURL, replacementListId + '/replacementListCandidates/contractHistoryCsv');
    }

    public static async getCSVStatesHistoryToDownload(replacementListId: string): Promise<any[]> {
        return csvService.getCsv(baseURL, replacementListId + '/replacementListCandidates/statesHistoryCsv');
    }

    public static async getCSVContactDataToDownload(replacementListId: string): Promise<any[]> {
        return csvService.getCsv(baseURL, replacementListId + '/replacementListCandidates/contactDataCsv');
    }

    public static async getCSVContactHistoryToDownload(replacementListId: string): Promise<any[]> {
        return csvService.getCsv(baseURL, replacementListId + '/replacementListCandidates/contactHistoryCsv');
    }

    public static async importCSVLoadReplacementList(formData: FormData): Promise<{ errorCsv: string, errorCount: number }> {
        return new Promise((resolve, reject) => {
            axios.post('/importReplacementList', formData, { headers: { 'Content-Type': 'multipart/form-data' }, baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject(error));
        });
    }

    public static async getDataReplacementRankedByTurn(replacementId: string, turnType: number | null): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get(replacementId + '/replacementlistcandidates?turn=' + (turnType !== null ? turnType : ''), { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async getReplacementListIsModify(replacementListId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get(replacementListId + '/modified', { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async sendJobOffer(jobOffer: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.post('sendMassiveOffer', jobOffer, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async getShippingHistoryList(replacementListId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get(replacementListId + '/massiveOffers', { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async getShippingContactList(replacementListId: string, shippingHistoryId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get(replacementListId + '/massiveOffers/' + shippingHistoryId + '/candidates', { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async getCandidates(replacementId: string): Promise<{ candidates: IReplacementCandidateIdentification[] }> {
        return new Promise((resolve, reject) => {
            axios.get(replacementId + '/candidates', { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async addReplacementCandidate(addReplacementCandidateCommand: { replacementListId: string, replacementListCandidate: any, orderCriteria: number}): Promise<any> {
        try {
            const response = await axios.post(addReplacementCandidateCommand.replacementListId + '/replacementListCandidate', addReplacementCandidateCommand, { baseURL });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}
