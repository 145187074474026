import { Component, Vue } from 'vue-property-decorator';
import { ReplacementContract, ReplacementStatus, SdInputTimepicker } from '@/Components';
import { Constants, ReplacementTabs } from '@/Domain/enum';
import { ReplacementInscriptionInfo, InscriptionContact, InscriptionReplacement } from '@/Domain/Entities';
import ReplacementService from '@/Services/ReplacementService';
import { ToasterService } from '@/Services/ToasterService';
import i18n from '../../lang';
import _ from 'lodash';
import { mapState } from 'vuex';
import ReplacementContactsService from '@/Services/ReplacementContactsService';
import ReplacementChange from '@/Domain/Entities/Replacement/ReplacementChange';

@Component({
    components: {
        ReplacementContract, ReplacementStatus, SdInputTimepicker
    },
    computed: {
        contactOrderReverse() {
            return _.orderBy(this.$data.contactList, 'date').reverse();
        },
        ...mapState('dateFormatStore', { dateFormat: 'dateFormat' })
    },
    watch: {
        '$route.params': {
            handler() {
                (this as ReplacementStatusContract).initList();
            },
            deep: true
        }
    }
})

export default class ReplacementStatusContract extends Vue {
    dateFormat!: string;
    replacementCandidateChanges!: ReplacementChange[];

    ReplacementTabs = ReplacementTabs;
    replacementListId: string = '';
    replacementListCandidateId: string = '';
    replacementListStatus = null as any;
    tabActive: any = ReplacementTabs.enum.STATUS;
    asideTabActive: any = ReplacementTabs.enum.CONTACT;
    openContact: boolean = false;
    loading: boolean = false;
    loadingReplacementListCandidate: boolean = false;
    showDeleteModal: boolean = false;
    contactList = null as any;
    contact = null as any;
    currentTabType = null as any;
    replacementListModified: boolean = false;
    fistContactRegister: boolean = false;
    submitted: boolean = false;
    loadingReplacementListModified: boolean = false;

    dateHourFormat = Constants.dateHourFormat;

    $refs!: {
        contactForm: HTMLFormElement
    };

    get replacementChangesWithOrder() {
        const listLength = this.replacementCandidateChanges.length;
        return this.replacementCandidateChanges.length ?
        this.replacementCandidateChanges.map((change, index) => ({ order: listLength - index, changeDate: change.changeDate, reasonFormatted: change.reasonFormatted, move: change.move }))
        : [];
    }

    editingContact(contactEditing: InscriptionContact, index: number) {
        this.openContact = true;
        this.contact = _.cloneDeep(contactEditing);
        this.contact.isEditing = true;
        this.contact.index = index;
    }

    createContact() {
        this.openContact = true;
        this.contact = new InscriptionContact({});
    }

    confirmWithModal(contactToRemove: InscriptionContact) {
        this.contact = contactToRemove;
        this.showDeleteModal = true;
    }

    removeContact() {
        this.showDeleteModal = false;
        this.loading = true;
        ReplacementContactsService.removeReplacementContact(this.replacementListCandidateId, this.contact.toServer())
            .then(() => {
                this.initList();
                this.loading = false;
                this.openContact = false;
                this.fistContactRegister = false;
                ToasterService.showSuccess(i18n.t('lang.toaster.saveChanged') as any, i18n.t('lang.toaster.goodJob') as any);
            })
            .catch(() => this.loading = false);
    }

    cancel() {
        this.openContact = false;
        this.contact = null;
        this.submitted = false;
        this.fistContactRegister = false;
    }

    save() {
        this.submitted = true;
        if (this.$refs.contactForm.querySelectorAll(':invalid').length) {
            return;
        }
        if (!this.contact.isEditing) {
            this.loading = true;
            const objectToServer = this.contact.toServer();
            ReplacementContactsService.addReplacementContact(this.replacementListCandidateId, objectToServer)
                .then(response => {
                    this.contact.id = response.id;
                    this.openContact = false;
                    this.loading = false;
                    ToasterService.showSuccess(i18n.t('lang.toaster.saveChanged') as any, i18n.t('lang.toaster.goodJob') as any);
                    this.submitted = false;
                    this.fistContactRegister = false;
                    this.initList();
                })
                .catch(() => this.loading = false);
        } else if (this.contact.isEditing) {
            this.loading = true;
            ReplacementContactsService.updateReplacementContact(this.replacementListCandidateId, this.contact.toServer())
                .then(() => {
                    this.openContact = false;
                    this.loading = false;
                    ToasterService.showSuccess(i18n.t('lang.toaster.saveChanged') as any, i18n.t('lang.toaster.goodJob') as any);
                    this.submitted = false;
                    this.fistContactRegister = false;
                    this.initList();
                })
                .catch(() => this.loading = false);
        }
    }

    getReplacementListCandidate() {
        this.loadingReplacementListCandidate = true;
        ReplacementService.getReplacementListCandidate(this.replacementListId, this.replacementListCandidateId)
            .then(response => {
                this.replacementListStatus = new ReplacementInscriptionInfo(response);
                this.replacementCandidateChanges = response.replacementChanges.map(change => new ReplacementChange(change));
                this.loadingReplacementListCandidate = false;
            }).catch(error => console.log(error));
    }

    getReplacementContacts() {
        ReplacementContactsService.getReplacementContacts(this.replacementListCandidateId)
            .then(response => {
                this.contactList = response.map(contact => new InscriptionContact(contact));
                this.loading = false;
            })
            .catch(() => this.loading = false);
    }

    getReplacementListIsModify() {
        this.loadingReplacementListModified = true;
        ReplacementService.getReplacementListIsModify(this.replacementListId)
            .then(response => {
                this.replacementListModified = response;
                this.loadingReplacementListModified = false;
            }).catch(error => console.log(error));
    }

    updateReplacementListIsModify() {
        this.getReplacementListIsModify();
    }

    goTo() {
        return this.$route.params.fromPage ?
            { name: this.$route.params.fromPage, params: this.$route.params.fromShippingHistory ? { ...JSON.parse(this.$route.params.fromShippingHistory) } : this.$route.params } :
            { name: 'ListReplacementList', params: { replacementId: this.replacementListId, hash: this.currentTabType } };
    }
    
    initList() {
        this.replacementListId = this.$route.params.id;
        this.replacementListCandidateId = this.$route.params.replacementListCandidateId;
        this.currentTabType = this.$route.params.hash;
        this.loading = true;
        this.getReplacementListCandidate();
        this.getReplacementContacts();
        this.getReplacementListIsModify();
    }

    mounted() {
        this.initList();
    }
}
