import { Component, Prop, Vue } from 'vue-property-decorator';
import { ReplacementCandidate } from '@/Domain/Entities';
import { Gender, YesNo, AddReplacementListCandidateCriteriaTranslations} from '@/Domain/enum';

@Component({})
export default class ReplacementCandidateFile extends Vue {
  @Prop() candidateSelected!: ReplacementCandidate;
  @Prop() isFormEditting!: boolean;
  @Prop() isReadMode!: boolean;
  @Prop() isCandidateFormDisabled!: boolean;
  @Prop() submitted!: boolean;
  @Prop() turnsOnProcess!: { [k: string]: any; };
  @Prop() nationalities!: any;
  @Prop() provinces!: any;
  @Prop() autonomousCommunityList!: any;
  @Prop() streetTypes!: any;
  @Prop() countries!: any;

  Gender = Gender;
  YesNo = YesNo;
  
  addReplacementListCandidateCriteriaOptions = Object.values(AddReplacementListCandidateCriteriaTranslations).map((option, index) => ({ text: option, value: index }));

  get showScore() {
    return this.isReadMode && !this.isFormEditting;
  }

  onFormUpdate(newValue: any, formKeyPath: string) {
    this.$emit('onFormUpdate', formKeyPath, newValue);
  }

    onEditFormClick() {
    this.$emit('onEditFormClick');
  }
}
