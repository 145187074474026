import axios from 'axios';
const baseURL = process.env.VUE_APP_ROOT_API + 'api/';

export default class CourtService {

    public static async getCourtList(): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.get('courtusers', { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async getCourtByEmail(email: string): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get('courtusers/' + email, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async addCourt(courtUser): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.post('courtusers', courtUser, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }

    public static async updateCourt(courtUser): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.put('courtusers/' + courtUser.email, courtUser, { baseURL })
                .then(response => resolve(response.data))
                .catch(error => reject());
        });
    }
}
