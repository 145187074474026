import Enum from '../interfaces/IEnum';

export const ReplacementTabs: Enum = {
    enum: {
        STATUS: 0,
        CONTRACT: 1,
        CONTACT: 2,
        CHANGES: 3
    }
};
