import { PersonalDataDetails } from '@/Domain/Entities';
import moment from 'moment';
import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({})
export default class PersonalDataForm extends Vue {
   @Prop() personalDataDetails!: PersonalDataDetails;
   @Prop() genderTranslations!: any;
   @Prop() submitted!: boolean;
   @Prop() nationalities!: any;
   @Prop() isDisabled!: boolean;
   @Prop() isReadMode!: boolean;

   maxRetirementAge: moment.Moment = moment().subtract(70, 'years').add(1, 'day');
   minUnderSixteen: moment.Moment = moment().subtract(16, 'years');

   get isInvalidDate() {
      return this.personalDataDetails.isAgeAboveMaximum() || this.personalDataDetails.isAgeBelowMinimum();
   }
   
   onFieldUpdate(updatedValue: string, key: string) {
      const newPersonalDataDetails = new PersonalDataDetails({ ...this.personalDataDetails.toServerInscription(), [key]: updatedValue });
      this.$emit('onFieldUpdate', newPersonalDataDetails);
   }
}
