import axios from 'axios';
import { ToasterService } from '../ToasterService';
import i18n from '../../lang';
import { SecurityService, mgrObject } from '@/Application/Services/SecurityService';
import { errorsDictionary } from './errorsDictionary';
import { router } from '@/router';

const mgr = new SecurityService();
const unauthorizedErrorCode = 403;
const unprocessableContentErrorCode = 422;

export default function setup() {
    axios.interceptors.response.use(async response => {
        signinSilent();
        return response;
    }, error => {
        if (error.response.status === unprocessableContentErrorCode) {
            mgr.signOut();
        }

        if (error.response.status === unauthorizedErrorCode) {
           router.push({ name: 'AccessDenied' });
        }

        const generalError = () => ToasterService.showError(i18n.t('lang.toaster.errorGeneralSubtitle') as string, i18n.t('lang.toaster.errorGeneralTitle') as string);

        if (error.response.data.includes('FileError')) {
            error = error.response.data;
        } else if (error.response.data.includes('FinishProcessError')) {
            error = error.response.data;
        } else if (error.response.data.includes('ValidateTieBreaksError')) {
            error = error.response.data;
        } else {
            const objError = errorsDictionary.find(obj => obj.errorText === error.response.data);
            const functionToCall = objError ? objError.toasterError : generalError;
            functionToCall();
            return;
        }  

        signinSilent();
        return Promise.reject(error);
    });
}

function signinSilent(): void {
    mgrObject.signinSilent().then(async () => {
        const newToken = await mgr.getAcessToken();
        axios.defaults.headers.common.Authorization = 'Bearer ' + newToken;
    }).catch(error => console.log(error));
}
