import moment from 'moment';
import { MultiLanguage, NoticeHistory } from '@/Domain/Entities';
import { EditionType } from '@/Domain/enum';

export default class OepDocument {
    private _id: string;
    private _name: MultiLanguage | string;
    private _publicationDate: moment.Moment | null;
    private _description: MultiLanguage | string;
    private _isVisible: boolean;
    private _fileDate: moment.Moment;
    private _fileName: string;
    private _extension: string;
    private _size: number;
    private _type: number;
    private _fileEdited: boolean;
    private _url: string;
    private _formFile: any;
    private _localUrl: string;
    private _editionType: number;
    private _uploaderName: string | '';
    private _uploaderFirstSurname: string | '';
    private _uploaderSecondSurname: string | '';
    private _hasNoticeHistory: boolean;
    private _isDropped: boolean = false;
    private _historyNoticeDocumentList: NoticeHistory[] = [];
    private _externalUrl: string;

    public get id() {
        return this._id;
    }
    public set id(value: string) {
        this._id = value;
    }

    public get name() {
        return this._name;
    }
    public set name(value: MultiLanguage | string) {
        this._name = value;
    }

    public get publicationDate() {
        return this._publicationDate;
    }
    public set publicationDate(value: moment.Moment | null) {
        this._publicationDate = value;
    }

    public get description() {
        return this._description;
    }
    public set description(value: MultiLanguage | string) {
        this._description = value;
    }

    public get isVisible() {
        return this._isVisible;
    }
    public set isVisible(value: boolean) {
        this._isVisible = value;
    }

    public get fileDate() {
        return this._fileDate;
    }
    public set fileDate(value: moment.Moment) {
        this._fileDate = value;
    }

    public get fileName() {
        return this._fileName;
    }
    public set fileName(value: string) {
        this._fileName = value;
    }

    public get extension() {
        return this._extension;
    }
    public set extension(value: string) {
        this._extension = value;
    }

    public get size() {
        return this._size;
    }
    public set size(value: number) {
        this._size = value;
    }

    public get type() {
        return this._type;
    }
    public set type(value: number) {
        this._type = value;
    }

    public get fileEdited() {
        return this._fileEdited;
    }
    public set fileEdited(value: boolean) {
        this._fileEdited = value;
    }

    public get url() {
        return this._url;
    }
    public set url(value: string) {
        this._url = value;
    }

    public get localUrl() {
        return this._localUrl;
    }
    public set localUrl(localUrl: string) {
        this._localUrl = localUrl;
    }

    public get formFile() {
        return this._formFile;
    }
    public set formFile(value: File | null) {
        this._formFile = value;
    }
    
    public get editionType() {
        return this._editionType;
    }
    public set editionType(value: number) {
        this._editionType = value;
    }
    
    public get uploaderName() {
        return this._uploaderName;
    }
    public set uploaderName(uploaderName: string) {
        this._uploaderName = uploaderName;
    }
    
    public get uploaderFirstSurname() {
        return this._uploaderFirstSurname;
    }
    public set uploaderFirstSurname(uploaderFirstSurname: string) {
        this._uploaderFirstSurname = uploaderFirstSurname;
    }
    
    public get uploaderSecondSurname() {
        return this._uploaderSecondSurname;
    }
    public set uploaderSecondSurname(uploaderSecondSurname: string) {
        this._uploaderSecondSurname = uploaderSecondSurname;
    }
    
    public get hasNoticeHistory() {
        return this._hasNoticeHistory;
    }
    public set hasNoticeHistory(hasNoticeHistory: boolean) {
        this._hasNoticeHistory = hasNoticeHistory;
    }
    
    public get isDropped() {
        return this._isDropped;
    }
    public set isDropped(isDropped: boolean) {
        this._isDropped = isDropped;
    }
    
    public get historyNoticeDocumentList() {
        return this._historyNoticeDocumentList;
    }
    public set historyNoticeDocumentList(historyNoticeDocumentList: NoticeHistory[]) {
        this._historyNoticeDocumentList = historyNoticeDocumentList;
    }

    public get externalUrl() {
        return this._externalUrl;
    }
    public set externalUrl(externalUrl: string) {
        this._externalUrl = externalUrl;
    }

    constructor(file: any) {
        this._id = file.id;
        this._name = new MultiLanguage(file.name);
        this._publicationDate = file.publicationDate ? moment.utc(file.publicationDate) : null;
        this._description = new MultiLanguage(file.description);
        this._isVisible = file.isVisible;
        this._fileDate = moment.utc(file.fileDate);
        this._fileName = file.fileName;
        this._extension = file.extension;
        this._size = file.size;
        this._type = file.type || 0;
        this._fileEdited = false;
        this._url = process.env.VUE_APP_ROOT_API + file.url + '?origin=' + document.location.origin;
        this._localUrl = file.localUrl;
        this._editionType = file.editionType !== undefined ? file.editionType : EditionType.enum.ADMININSCRIPTION;
        this._formFile = file.formFile === undefined && !file.externalUrl ? {
            extension: file.extension,
            name: file.fileName,
            fileDate: moment.utc(file.fileDate),
            size: file.size
        } : file.formFile;
        this._uploaderName = file.uploader ? file.uploader.name : '';
        this._uploaderFirstSurname = file.uploader ? file.uploader.firstSurname : '';
        this._uploaderSecondSurname = file.uploader ? file.uploader.secondSurname : '';
        this._hasNoticeHistory = file.hasNoticeHistory ? file.hasNoticeHistory : false;
        this._externalUrl = file.externalUrl ? file.externalUrl : null;
    }

    public uploaderNameAdmin() {
        return `${this.uploaderName} ${this.uploaderFirstSurname} ${this.uploaderSecondSurname}`;
    }

    public resetEditionDocument() {
        this.formFile = null;
        this.fileDate = moment.utc();
        this.uploaderName = '';
        this.uploaderFirstSurname = '';
        this.uploaderSecondSurname = '';
    }

    public hourFormat() {
        const hourFormat = 'HH:mm';
        return this.fileDate ? moment(this.fileDate.toISOString()).format(hourFormat) : '';
    }

    public update(file: any): void {
        this._name = new MultiLanguage(file.name);
        this._publicationDate = moment.utc(file.publicationDate);
        this._description = new MultiLanguage(file.description);
        this._isVisible = file.isVisible;
        this._fileDate = moment.utc(file.fileDate);
        this._fileName = file.fileName;
        this._extension = file.extension;
        this._size = file.size;
        this._type = file.type || 0;
        this._fileEdited = false;
        this._url = process.env.VUE_APP_ROOT_API + file.url + '?origin=' + document.location.origin;
        this._editionType = EditionType.enum.ADMININSCRIPTION;
        this._formFile = {
            extension: file.extension,
            name: file.fileName,
            fileDate: moment.utc(file.fileDate),
            size: file.size
        };
        this._uploaderName = file.uploader ? file.uploader.name : '';
        this._uploaderFirstSurname = file.uploader ? file.uploader.firstSurname : '';
        this._uploaderSecondSurname = file.uploader ? file.uploader.secondSurname : '';
        this._externalUrl = file.externalUrl ? file.externalUrl : null;
    }

    public toServer(parentUrl: string, ownerId: string) {
        return {
            ownerId,
            parentUrl,
            id: this.id,
            name: this.name.toString(),
            publicationDate: this.externalUrl ? moment.utc().local().subtract(1, 'm').toISOString() : this.publicationDate ? this.publicationDate.toISOString() : null,
            description: this.description ? this.description.toString() : '',
            type: this.type || 0,
            fileEdited: this.fileEdited,
            isVisible: this.isVisible,
            formFile: this.formFile,
            editionType: EditionType.enum.ADMININSCRIPTION,
            externalUrl: this.externalUrl ? this.externalUrl : ''
        };
    }
}
