import {ContactData } from '@/Domain/Entities';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class ContactDataForm extends Vue {
  @Prop() contactData!: ContactData;
  @Prop() isDisabled!: boolean;
  @Prop() submitted!: boolean;

  onFieldUpdate(updatedValue: string, key: string) {
    const newContactData = new ContactData({ ...this.contactData.toServer(), [key]: updatedValue });
    this.$emit('onFieldUpdate', newContactData);
  }
}
